import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import { Trans, useTranslation } from "react-i18next";

const ReportDialog = ({ open, onClose, objectId, objectType, onSubmit }) => {
  const [reason, setReason] = useState("");

  const { t, i18n } = useTranslation();

  const handleSubmit = () => {
    if (reason.trim() !== "") {
      onSubmit({ objectId, objectType, reason });
      onClose();
    } else {
      alert("Please enter a reason for reporting.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Trans>Report {objectType}</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          <Trans>Please provide a reason for reporting.</Trans>
        </Typography>
        <TextareaAutosize
          minRows={4}
          style={{ width: "100%" }}
          placeholder={t("Enter your reason here...")}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button onClick={handleSubmit} color="primary">
          <Trans>Submit</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
