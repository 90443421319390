import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player/lazy";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enStrings from "react-timeago/lib/language-strings/en";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import Notification from "../../../Notification";
import Card from "../../../components/Card";
import axiosInstance from "../../../useApi";
import {
  getInitialsBis,
  retrieveError,
  stringToColorBis,
} from "../../../utilities/constants";
import FormAnswerPost from "./form-answer-posts";

const PostDetails = () => {
  const location = useLocation();
  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [patchRequestMade, setPatchRequestMade] = useState(false);

  const mutation = useMutation({
    mutationFn: (user) => {
      return axiosInstance.patch(
        "https://api-beta.seeks.biz/notifications/batch_update/",
        {
          is_read_posts: [
            window.location.pathname.substring(
              window.location.pathname.lastIndexOf("/") + 1
            ),
          ],
        }
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
    },
    onSuccess: (data, variables, context) => {
      setPatchRequestMade(true);
    },
  });

  useEffect(() => {
    if (!patchRequestMade) {
      mutation.mutate();
    }
  }, [patchRequestMade, mutation]);

  const fetchMyPost = async () => {
    try {
      const { data } = await axiosInstance.get(
        "/socialmedia/posts/" +
          window.location.pathname.substring(
            window.location.pathname.lastIndexOf("/") + 1
          )
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 404) {
        return [];
      }
    }
  };

  const handleRefetchPosts = () => {};

  const { data: post } = useQuery(
    ["post", location.pathname],
    () => fetchMyPost(),
    {
      retry: false,
    }
  );
  const formatImages = (images) => {
    let formattedImages = [];
    images.forEach((element) => {
      formattedImages.push({
        original: element.image,
        thumbnail: element.image,
        originalHeight: "360",
      });
    });
    return formattedImages;
  };

  return (
    <>
      <Container>
        <Row>
          <Col sm={12} style={{ padding: "1rem 0.5rem 0rem 0.5rem" }}>
            <Card.Body className=" p-0">
              <Row>
                <Col lg={12}>
                  {!post ? (
                    <div className="col-sm-12 text-center">
                      <CircularProgress />
                    </div>
                  ) : post && post.length <= 0 ? (
                    <div style={{ textAlign: "center", fontSize: "18px" }}>
                      <Trans>No post found</Trans>
                    </div>
                  ) : (
                    <div>
                      <div className="card card-block card-stretch card-height">
                        <div className="card-body">
                          <div className="user-post-data">
                            <div className="d-flex justify-content-between">
                              <div className="me-3">
                                <Avatar
                                  src={post?.author?.avatar}
                                  sx={{
                                    bgcolor:
                                      post?.author?.avatar &&
                                      post?.author?.avatar !== "null"
                                        ? "transparent"
                                        : stringToColorBis(
                                            post?.author?.first_name,
                                            post?.author?.last_name
                                          ),
                                  }}
                                >
                                  {post?.author?.avatar &&
                                  post?.author?.avatar !== "null"
                                    ? post?.author?.avatar
                                    : getInitialsBis(
                                        post?.author?.first_name,
                                        post?.author?.last_name
                                      )}
                                </Avatar>
                              </div>
                              <div className="w-100">
                                <div className="d-flex  justify-content-between">
                                  <div>
                                    <h5 className="mb-0 d-inline-block">
                                      {post?.author?.first_name +
                                        " " +
                                        post?.author?.last_name}
                                    </h5>
                                    <span className="mb-0 ps-1 d-inline-block">
                                      <Trans>Added a new Post in sector</Trans>
                                      &nbsp;{post.business_sector}
                                    </span>
                                    <p className="mb-0 text-primary">
                                      <TimeAgo
                                        date={post.created_at}
                                        formatter={
                                          localStorage.getItem("i18next") ===
                                          "fr"
                                            ? formatterBis
                                            : formatter
                                        }
                                      />
                                    </p>
                                  </div>
                                  <div className="card-post-toolbar">
                                    <div
                                      style={{
                                        textAlign: "end",
                                      }}
                                    >
                                      <LocationOnIcon />{" "}
                                      {post.location_type === "local" ? (
                                        post.location +
                                        " (+" +
                                        post.location_radius +
                                        " km)"
                                      ) : post.location_type === "régional" ? (
                                        post.location
                                      ) : (
                                        <Trans>National</Trans>
                                      )}
                                    </div>
                                    <div style={{ textAlign: "end" }}>
                                      <EventIcon />{" "}
                                      {format(
                                        new Date(post.valid_until),
                                        "dd-MM-yyyy"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <p style={{ whiteSpace: "pre-line" }}>
                              {post.content}
                            </p>
                          </div>
                          <div className="user-post mt-3">
                            {post.images && post.images.length > 0 ? (
                              <ImageGallery
                                loading={"lazy"}
                                items={formatImages(post.images)}
                                showPlayButton={false}
                                thumbnailPosition="right"
                                useBrowserFullscreen={false}
                              />
                            ) : null}
                          </div>
                          <div className="user-post mt-3">
                            <Grid container spacing={2}>
                              {post.attached_files &&
                              post.attached_files.length > 0 &&
                              post.attached_files.find(
                                (elt) => !elt.attached?.endsWith(".pdf")
                              )
                                ? post.attached_files
                                    .filter(
                                      (elt) => !elt.attached?.endsWith(".pdf")
                                    )
                                    .map((video) => (
                                      <Grid item xl={4} md={6} xs={12}>
                                        <ReactPlayer
                                          url={video.attached}
                                          controls={true}
                                          width="100%"
                                          height="100%"
                                        />
                                      </Grid>
                                    ))
                                : null}
                            </Grid>
                          </div>
                          <div className="user-post mt-3">
                            <Grid container spacing={2}>
                              {post.attached_files &&
                              post.attached_files.length > 0 &&
                              post.attached_files.find((elt) =>
                                elt.attached?.endsWith(".pdf")
                              )
                                ? post.attached_files
                                    .filter((elt) =>
                                      elt.attached?.endsWith(".pdf")
                                    )
                                    .map((file) => (
                                      <Grid item key={file.id}>
                                        <a
                                          href={file.attached}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Box
                                            elevation={3}
                                            sx={{
                                              position: "relative",
                                            }}
                                          >
                                            <PictureAsPdfIcon
                                              color="error"
                                              sx={{ fontSize: 64 }}
                                            />
                                            <Tooltip
                                              title={
                                                file.attached.split(
                                                  "/media/posts/attached/"
                                                )[1]
                                              }
                                            >
                                              <Typography
                                                sx={{
                                                  maxWidth: 100,
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {
                                                  file.attached.split(
                                                    "/media/posts/attached/"
                                                  )[1]
                                                }
                                              </Typography>
                                            </Tooltip>
                                          </Box>
                                        </a>
                                      </Grid>
                                    ))
                                : null}
                            </Grid>
                          </div>

                          <FormAnswerPost
                            idPost={post.id}
                            answersCount={post.answers.length}
                            handleRefetchPosts={handleRefetchPosts}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </Container>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default PostDetails;
