import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player/lazy";
import TimeAgo from "react-timeago";
import frenchStrings from "react-timeago/lib/language-strings/fr";

import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enStrings from "react-timeago/lib/language-strings/en";

import {
  getInitials,
  retrieveError,
  stringToColor,
} from "../../../utilities/constants";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import Notification from "../../../Notification";
//image
import ConfirmDialog from "../../../ConfirmDialog";
import axiosInstance from "../../../useApi";
import MyPostAnswers from "./my-post-answers";

const ListMyPosts = ({ setPostsCount }) => {
  const { t, i18n } = useTranslation();
  const fullname = localStorage.getItem("fullname");

  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [profilePictureUrl, setProfilePictureUrl] = useState(
    localStorage.getItem("avatar") && localStorage.getItem("avatar") !== "null"
      ? localStorage.getItem("avatar")
      : "null"
  );
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [orderByAnswers, setOrderByAnswers] = useState(false);

  const fetchMyPosts = async () => {
    const { data } = await axiosInstance.get(
      "/socialmedia/myposts/?order_by_answers=" + orderByAnswers
    );
    setPostsCount(data.count);
    return data;
  };

  const deleteMyPost = async (idPost) => {
    mutationDeletePost.mutate(idPost);
  };

  const mutationDeletePost = useMutation({
    mutationFn: (idPost) => {
      return axiosInstance.delete(
        "https://api-beta.seeks.biz/socialmedia/myposts/" + idPost
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la suppression du post : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Post deleted successfully!"),
        type: "success",
      });
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      refetch();
    },
  });

  const {
    data: myPosts,
    isLoadingPosts,
    refetch,
  } = useQuery(["myPosts"], () => fetchMyPosts(), {});

  const formatImages = (images) => {
    let formattedImages = [];
    images.forEach((element) => {
      formattedImages.push({
        original: element.image,
        thumbnail: element.image,
        originalHeight: "360",
      });
    });
    return formattedImages;
  };

  const handleOrderByAnswersChange = async () => {
    await new Promise((resolve) => {
      setOrderByAnswers((prevValue) => {
        const newValue = !prevValue;
        resolve();
        return newValue;
      });
    });

    refetch();
    console.log(orderByAnswers);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "-16px" }}>
        <hr style={{ width: "100%", marginRight: "24px" }} />
        <Select
          value={orderByAnswers}
          onChange={handleOrderByAnswersChange}
          id="filterOrderPosts"
          sx={{
            "& fieldset": {
              border: "none",
            },
          }}
        >
          <MenuItem value={false}>
            <Trans>See most recent posts</Trans>
          </MenuItem>
          <MenuItem value={true}>
            <Trans>See most recent answers</Trans>
          </MenuItem>
        </Select>
      </Box>
      {!myPosts || myPosts.length <= 0 ? (
        <div className="col-sm-12 text-center">
          <CircularProgress />
        </div>
      ) : myPosts.results && myPosts.results.length <= 0 ? (
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          <Trans>No post found</Trans>
        </div>
      ) : (
        myPosts?.results.map((post, index) => (
          <div key={post.id}>
            <div className="card card-block card-stretch card-height">
              <div className="card-body">
                <div className="user-post-data">
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Avatar
                        src={profilePictureUrl}
                        sx={{ bgcolor: stringToColor() }}
                      >
                        {profilePictureUrl && profilePictureUrl !== "null"
                          ? profilePictureUrl
                          : getInitials()}
                      </Avatar>
                    </div>
                    <div className="w-100">
                      <div className="d-flex  justify-content-between">
                        <div>
                          <h5 className="mb-0 d-inline-block">{fullname}</h5>
                          <span className="mb-0 ps-1 d-inline-block">
                            <Trans>Added a new Post in sector</Trans>
                            &nbsp;{post.business_sector}
                          </span>
                          <p className="mb-0 text-primary">
                            <TimeAgo
                              date={post.created_at}
                              formatter={
                                localStorage.getItem("i18next") === "fr"
                                  ? formatterBis
                                  : formatter
                              }
                            />
                          </p>
                        </div>
                        <div className="card-post-toolbar">
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <IconButton
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    "Are you sure you want to delete this post?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    deleteMyPost(post.id);
                                  },
                                });
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                          <div
                            style={{ justifyContent: "right", display: "flex" }}
                          >
                            <LocationOnIcon />{" "}
                            {post.location_type === "local" ? (
                              post.location +
                              " (+" +
                              post.location_radius +
                              " km)"
                            ) : post.location_type === "régional" ? (
                              post.location
                            ) : (
                              <Trans>National</Trans>
                            )}
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <EventIcon />{" "}
                            {format(new Date(post.valid_until), "dd-MM-yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p style={{ whiteSpace: "pre-line" }}>{post.content}</p>
                </div>
                <div className="user-post mt-3">
                  {post.images && post.images.length > 0 ? (
                    <ImageGallery
                      loading={"lazy"}
                      items={formatImages(post.images)}
                      showPlayButton={false}
                      thumbnailPosition="right"
                      useBrowserFullscreen={false}
                    />
                  ) : null}
                </div>
                <div className="user-post mt-3">
                  <Grid container spacing={2}>
                    {post.attached_files &&
                    post.attached_files.length > 0 &&
                    post.attached_files.find(
                      (elt) => !elt.attached?.endsWith(".pdf")
                    )
                      ? post.attached_files
                          .filter((elt) => !elt.attached?.endsWith(".pdf"))
                          .map((video) => (
                            <Grid item xl={4} md={6} xs={12}>
                              <ReactPlayer
                                url={video.attached}
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </div>
                <div className="user-post mt-3">
                  <Grid container spacing={2}>
                    {post.attached_files &&
                    post.attached_files.length > 0 &&
                    post.attached_files.find((elt) =>
                      elt.attached?.endsWith(".pdf")
                    )
                      ? post.attached_files
                          .filter((elt) => elt.attached?.endsWith(".pdf"))
                          .map((file) => (
                            <Grid item key={file.id}>
                              <a
                                href={file.attached}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box
                                  elevation={3}
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    color="error"
                                    sx={{ fontSize: 64 }}
                                  />
                                  <Tooltip
                                    title={
                                      file.attached.split(
                                        "/media/posts/attached/"
                                      )[1]
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: 100,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        file.attached.split(
                                          "/media/posts/attached/"
                                        )[1]
                                      }
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </a>
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </div>
                <MyPostAnswers answers={post.answers} />
              </div>
            </div>
          </div>
        ))
      )}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default ListMyPosts;
