import React, { useState, useContext, useEffect, useRef } from "react";
import { HeaderContext } from "./headerContext";
import { useLocation } from "react-router-dom";
import { JoyrideContext } from "../../../../joyrideContext";
import Joyride from "react-joyride";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { Dropdown, Nav, Card, Container, Image } from "react-bootstrap";
import MyNotifications from "../../../../views/dashboard/app/my-notifications";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Link, useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import i18n from "../../../../i18n";
import {
  Autocomplete,
  Avatar,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Tooltip,
  InputAdornment,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../../useApi";
import { retrieveError } from "../../../../utilities/constants";

import { IconLanguage } from "@tabler/icons";
import logo from "../../../../assets/images/logo-blanc-bleu.png";
import England from "../../../../assets/images/flags/england.png";
import France from "../../../../assets/images/flags/france.png";
import Spain from "../../../../assets/images/flags/spain.png";
import { Trans, useTranslation } from "react-i18next";
import CustomToggle from "../../../dropdowns";
import {
  getInitialsBis,
  stringToColorBis,
} from "../../../../utilities/constants";

import ConfirmDialog from "../../../../ConfirmDialog";
import Notification from "../../../../Notification";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const fullname = localStorage.getItem("fullname");

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const {
    runValue,
    updateRunValue,
    stepIndexValue,
    updateStepIndexValue,
    updateTourActiveValue,
  } = useContext(JoyrideContext);

  const { t, i18n } = useTranslation();

  const countries = [
    {
      label: t("French"),
      src: France,
      link: " ",
      value: "fr",
    },
    {
      label: t("English"),
      src: England,
      link: " ",
      value: "en",
    },
  ];

  const [open, setOpen] = useState(false);
  const [isReverseChecked, setIsReverseChecked] = useState(false);

  const [openFlags, setOpenFlags] = useState(false);
  const [valueOptionsWords, setValueOptionsWords] = useState(
    JSON.parse(localStorage.getItem("words"))
  );

  const [country, setCountry] = useState(
    cookies.get("i18next") === "en"
      ? England
      : cookies.get("i18next") === "es"
      ? Spain
      : France
  );

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [suggestion, setSuggestion] = useState("");

  const sendSuggestion = () => {
    mutationSendSuggestion.mutate(suggestion);
  };

  const mutationSendSuggestion = useMutation({
    mutationFn: (suggestion) => {
      return axiosInstance.post("https://api-beta.seeks.biz/suggestions/", {
        suggestion: suggestion,
        title: "suggestion",
      });
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setOpen(false);
    },
  });

  const { headerValue, updateHeaderValue, avatarValue, fullnameValue } =
    useContext(HeaderContext);

  useEffect(() => {
    if (location.pathname !== "/") {
      updateHeaderValue("");
    }
  }, [location.pathname, updateHeaderValue]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleReverseChecked = () => {
    setIsReverseChecked(!isReverseChecked);
    handleInputChange(isReverseChecked);
  };

  const handleInputChange = (event) => {
    if (typeof event === "boolean") {
      if (headerValue !== "") {
        if (
          isReverseChecked === true &&
          JSON.parse(headerValue).exclude_words?.length > 0
        ) {
          let newValue = {
            only_words: JSON.parse(headerValue).exclude_words,
          };
          updateHeaderValue(JSON.stringify(newValue));
        } else if (
          isReverseChecked === false &&
          JSON.parse(headerValue).only_words?.length > 0
        ) {
          let newValue = {
            exclude_words: JSON.parse(headerValue).only_words,
          };
          updateHeaderValue(JSON.stringify(newValue));
        }
      }
    } else {
      if (isReverseChecked) {
        if (event.length > 0) {
          updateHeaderValue(JSON.stringify({ exclude_words: event }));
        } else {
          updateHeaderValue("");
        }
      } else {
        if (event.length > 0) {
          updateHeaderValue(JSON.stringify({ only_words: event }));
        } else {
          updateHeaderValue("");
        }
      }
    }
  };

  const handleChange = (event) => {
    setCountry(event.target.value);
    if (event.target.value.startsWith("/static/media/en")) {
      i18n.changeLanguage("en");
      cookies.set("i18next", "en");
      window.location.reload();
    } else if (event.target.value.startsWith("/static/media/sp")) {
      i18n.changeLanguage("es");
      cookies.set("i18next", "es");
      window.location.reload();
    } else {
      i18n.changeLanguage("fr");
      cookies.set("i18next", "fr");
      window.location.reload();
    }

    handleCloseFlags();
  };

  const emptyLocalStorage = () => {
    let valueSkipJoyride = localStorage.getItem("autoSkipJoyride");
    localStorage.clear();
    localStorage.setItem("autoSkipJoyride", valueSkipJoyride);
  };

  const handleCloseFlags = () => {
    setOpenFlags(false);
  };

  const handleOpenFlags = () => {
    setOpenFlags(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const currentPath = location.pathname;

  const handleCallback = (data) => {
    const { action, index, lifecycle, type } = data;
    const nextStepIndex = index + (action === "prev" ? -1 : 1);

    if (type === "step:after" && index === 0) {
      updateStepIndexValue(nextStepIndex);
    } else if (type === "step:after" && index === 8) {
      if (action === "prev") {
        navigate("/dashboard/app/profile");
        updateStepIndexValue(nextStepIndex);
      }
      if (action === "next") {
        updateRunValue(false);
        updateTourActiveValue(false);
        setConfirmDialog({
          isOpen: true,
          title:
            "Vous avez terminé le tutoriel! Préférez-vous ne pas le revoir lors de votre prochaine visite?",
          subTitle:
            'Vous pouvez le réactiver dans votre profil à tout moment, en cliquant sur le bouton en bas de "Vos paramètres".',
          onConfirm: () => {
            updateRunValue(false);
            updateTourActiveValue(false);
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            localStorage.setItem("autoSkipJoyride", "true");
          },
        });
      }
    } else if (type === "step:after" && index === 7) {
      if (action === "next") {
        updateStepIndexValue(nextStepIndex);
        navigate("/dashboard/app/user-account-setting");
      } else if (action === "prev") {
        updateStepIndexValue(nextStepIndex);
      }
    } else if (type === "step:after" && index === 6) {
      if (action === "next") {
        updateStepIndexValue(nextStepIndex);
      } else {
        updateStepIndexValue(nextStepIndex);
      }
    } else if (type === "step:after" && index === 5) {
      if (action === "prev") {
        updateStepIndexValue(nextStepIndex - 1);
        navigate("/");
      }
      if (action === "next") {
        updateStepIndexValue(nextStepIndex);
      }
    } else if (type === "step:after" && index === 4) {
      updateStepIndexValue(nextStepIndex);

      if (action === "next") {
        // updateRunValue(false);
        // updateTourActiveValue(true);
        navigate("/dashboard/app/profile");
      }
    } else if (type === "step:after" && index === 3) {
      if (action === "next") {
        updateStepIndexValue(nextStepIndex);
      } else {
        updateStepIndexValue(nextStepIndex);
      }
    } else if (type === "step:after" && index === 2) {
      if (action === "next") {
        updateStepIndexValue(nextStepIndex);
      } else {
        updateStepIndexValue(nextStepIndex);
      }
    } else if (type === "step:after" && index === 1) {
      if (action === "next") {
        updateStepIndexValue(nextStepIndex);
      } else {
        updateStepIndexValue(nextStepIndex);
      }
    } else if (action === "reset" || lifecycle === "complete") {
      updateRunValue(false);
      updateTourActiveValue(false);
    } else if (action === "skip") {
      setConfirmDialog({
        isOpen: true,
        title: "Êtes vous sûr de vouloir passer le tutoriel?",
        subTitle:
          'Vous pouvez le réactiver dans votre profil à tout moment, en cliquant sur le bouton en bas de "Vos paramètres".',
        onConfirm: () => {
          updateRunValue(false);
          updateTourActiveValue(false);
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          localStorage.setItem("autoSkipJoyride", "true");
        },
      });
    }
  };

  const menu = useRef(null);

  const steps = [
    {
      target: "#list-posts-home",
      content: (
        <div>
          Bienvenue sur Seeks.biz! <br />
          <br />
          Vous pouvez retrouver ici des postes correspondant aux mots que vous
          avez choisi lors de votre inscription, ou des posts en lien avec les
          secteurs d'activités de vos mots si trop peu de posts ont été trouvés.
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#autocompleteFilterWords",
      content: (
        <div>
          Ce champ permet de filtrer les posts affichés sur la page par rapport
          à vos mots. <br />
          <br />
          Par défaut, tous les résultats sont affichés. <br />
          <br />
          En y ajoutant des mots, vous ne rechercherez que les posts contenant
          ces mots. <br />
          <br />
          Si vous cochez 'Exclure' au contraire, cela excluera les posts
          contenant les mots choisis et affichera tous les autres.
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#formStartPost",
      content: (
        <div>
          Vous pourrez cliquer ici pour rédiger un nouveau post. Veillez à être
          le plus précis et concis possible dans votre demande.
          <br />
          Vous avez la possibilité d'ajouter 5 photos, ainsi que 5 documents
          et/ou vidéos.
          <br />
          Vous devez ensuite choisir une localisation, une date limite pour la
          visibilité de votre post, ainsi que le secteur d'activité.
          <br />
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      content: <div>Cliquez ici pour ouvrir le menu!</div>,
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: "#avatarToBeClicked",
      title: "Menu",
    },
    {
      target: "#linkMyPostsStep",
      content: (
        <div>
          Ce menu permet de : <br />
          <br />
          -naviguer vers votre profil, rassemblant les posts que vous avez créés
          ainsi que leurs réponses, et les réponses que vous avez fait à vos
          posts,
          <br />
          <br />
          -naviguer vers les paramètres de votre compte, comprenant les
          informations que vous avez fourni à l'inscription,
          <br />
          <br />
          -vous déconnecter.
          <br />
          <br />
          Nous allons naviguer d'abord vers votre profil, cliquez sur "Suivant".
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#navItemMyPostsStep",
      content: (
        <div>
          La page est séparée en 2 onglets.
          <br />
          <br />
          Sur ce premier onglet, vous pouvez retrouver toutes vos demandes et
          les réponses que d'autres utilisateurs vous ont apportées pour ces
          dernières.
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#filterOrderPosts",
      content: (
        <div>
          Vous pouvez cliquer sur ce filtre, pour changer le tri par date de
          création du post, ou par date de la dernière réponse reçue.
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#navItemMyAnswersStep",
      content: (
        <div>
          Sur ce deuxième onglet, vous pouvez retrouver toutes les demandes
          auxquelles vous avez répondu.
          <br /> <br />
          Naviguons maintenant vers vos paramètres, cliquez sur "Suivant".
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: "#buttonAddWords",
      content: (
        <div>
          Enfin, dans votre profil, vous pouvez retrouver et modifier toutes vos
          informations, dont vos mots. <br /> <br />
          Lorsque vous choisissez un nouveau mot, nous vous indiquons combien de
          posts ont été trouvés pour ce dernier.
          <br /> <br />
          Pensez à cliquer sur le bouton "Valider changements" en bas pour les
          changements effectifs.
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
    },
  ];

  const handleClickOpen = () => {
    if (stepIndexValue && stepIndexValue === 3) {
      updateStepIndexValue(4);
    }
  };

  return (
    <>
      {localStorage.getItem("autoSkipJoyride") === "true" ? null : (
        <Joyride
          continuous
          hideCloseButton
          callback={handleCallback}
          run={runValue}
          showProgress
          showSkipButton
          steps={steps}
          disableScrolling={true}
          stepIndex={stepIndexValue}
          locale={{
            back: t("Back"),
            last: t("Last"),
            next: t("Next"),
            skip: t("Skip"),
          }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: "#3f50b5",
            },
          }}
        />
      )}
      <div className="iq-top-navbar">
        <Nav
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                <Image src={logo} style={{ maxWidth: "54px" }} alt="logo" />

                <h3 className="logo-title d-none d-sm-block">Seeks</h3>
              </Link>
            </div>
            {currentPath === "/" ? (
              <div
                style={{
                  display: "flex",
                  // maxWidth: "33%",
                  minWidth: "342px",
                  paddingTop: "8px",
                }}
              >
                <Autocomplete
                  id="autocompleteFilterWords"
                  multiple
                  limitTags={1}
                  options={valueOptionsWords || []}
                  getOptionLabel={(option) =>
                    option.word + " (" + option.theme + ")"
                  }
                  // getOptionsSelected={(option, value) => value.id === option.id}
                  // value={valueChipWords}
                  onChange={(event, newValue) => {
                    handleInputChange(newValue);
                  }}
                  fullWidth
                  disableCloseOnSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label={<Trans>Filter your words</Trans>}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Tooltip
                            title={t(
                              "You can add words here, to filter results on these only. If you check the box, it will remove these words from the search."
                            )}
                          >
                            <InputAdornment position="end">
                              <HelpOutlineIcon color="primary" />
                            </InputAdornment>
                          </Tooltip>
                        ),
                      }}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isReverseChecked}
                      onChange={handleReverseChecked}
                    />
                  }
                  label={t("Exclude")}
                  style={{ marginLeft: "4px" }}
                />
              </div>
            ) : null}
            <ul className="navbar-nav navbar-list">
              <Nav.Item as="li">
                <Link to="/" className="d-flex align-items-center">
                  <i className="material-symbols-outlined">home</i>
                  <span className="mobile-text d-none ms-3">Home</span>
                </Link>
              </Nav.Item>
              <Dropdown as="li" className="nav-item">
                <Dropdown.Toggle
                  href="/"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  <IconLanguage stroke={1.5} size="1.3rem" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop">
                  <Card className="shadow-none m-0">
                    <Card.Header className="d-flex justify-content-between bg-primary">
                      <div className="header-title">
                        <h5 className="mb-0 text-white">
                          <Trans>Language change</Trans>
                        </h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <div className="iq-friend-request">
                        <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
                          <Select
                            style={{ marginLeft: "-4px" }}
                            open={openFlags}
                            onClose={handleCloseFlags}
                            onOpen={handleOpenFlags}
                            value={country}
                            name="country"
                            onChange={handleChange}
                            MenuProps={{
                              TransitionProps: {
                                onExited: () => setOpenFlags(false),
                              },
                            }}
                          >
                            {countries.map((option, key) => (
                              <MenuItem
                                value={option.src}
                                key={option.label}
                                id="selectCountryMenuItem"
                              >
                                <img
                                  src={option.src}
                                  alt={option.label}
                                  style={{
                                    maxWidth: "16px",
                                    maxHeight: "16px",
                                    marginRight: "8px",
                                  }}
                                />
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>

              <MyNotifications></MyNotifications>

              <Nav.Item as="li">
                <Link
                  to="#"
                  onClick={handleToggle}
                  className="d-flex align-items-center"
                >
                  <Tooltip title={t("Any suggestion to improve SEEKS?")}>
                    <TipsAndUpdatesIcon />
                  </Tooltip>
                </Link>
              </Nav.Item>

              {/* <Nav.Item as="li" className="d-lg-none">
              <Link
                to="/dashboard/app/notification"
                className="d-flex align-items-center"
              >
                <i className="material-symbols-outlined">notifications</i>
                <span className="mobile-text  ms-3 d-none">Notifications</span>
              </Link>
  </Nav.Item>*/}
              {/* <Nav.Item className="nav-item d-none d-lg-none">
                <Link
                  to="#"
                  className="dropdown-toggle d-flex align-items-center"
                  id="mail-drop-1"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-symbols-outlined">mail</i>
                  <span className="mobile-text  ms-3">Message</span>
                </Link>
              </Nav.Item> */}
              <Dropdown
                as="li"
                className="nav-item user-dropdown"
                onClick={handleClickOpen}
                id="avatarToBeClicked"
              >
                <Dropdown.Toggle
                  href="#"
                  as={CustomToggle}
                  variant="d-flex align-items-center"
                >
                  <Avatar
                    ref={menu}
                    src={avatarValue}
                    sx={{
                      bgcolor:
                        avatarValue && avatarValue !== "null"
                          ? "transparent"
                          : stringToColorBis(
                              fullnameValue?.split(" ")[0],
                              fullnameValue?.split(" ")[1]
                            ),
                    }}
                  >
                    {avatarValue && avatarValue !== "null"
                      ? avatarValue
                      : getInitialsBis(
                          fullnameValue?.split(" ")[0],
                          fullnameValue?.split(" ")[1]
                        )}
                  </Avatar>
                </Dropdown.Toggle>
                <Dropdown.Menu className="sub-drop caption-menu">
                  <Card className="m-0" id="linkMyPostsStep">
                    <Card.Header>
                      <div className="header-title">
                        <h5 className="mb-0 ">
                          <Trans>Hello</Trans> {fullname}
                        </h5>
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <Link to="/dashboard/app/profile" className="mb-0 h6">
                        <div className="d-flex align-items-center iq-sub-card border-0">
                          <span className="material-symbols-outlined">
                            line_style
                          </span>
                          <div className="ms-3">
                            <Trans>My profile</Trans>
                          </div>
                        </div>
                      </Link>
                      {/* <div className="d-flex align-items-center iq-sub-card border-0">
                        <span className="material-symbols-outlined">
                          edit_note
                        </span>
                        <div className="ms-3">
                          <Link to="#" className="mb-0 h6">
                            Edit Profile
                          </Link>
                        </div>
                      </div> */}

                      <Link
                        to="/dashboard/app/user-account-setting"
                        className="mb-0 h6"
                      >
                        <div className="d-flex align-items-center iq-sub-card border-0">
                          <span className="material-symbols-outlined">
                            manage_accounts
                          </span>
                          <div className="ms-3">
                            <Trans>Account settings</Trans>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="/auth/sign-in"
                        onClick={emptyLocalStorage}
                        className="mb-0 h6"
                      >
                        <div className="d-flex align-items-center iq-sub-card">
                          <span className="material-symbols-outlined">
                            login
                          </span>
                          <div className="ms-3">
                            <Trans>Sign out</Trans>
                          </div>
                        </div>
                      </Link>
                      {/* <div className=" iq-sub-card">
                        <h5>Chat Settings</h5>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-success md-14">
                          circle
                        </i>
                        <div className="ms-3">Online</div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-warning md-14">
                          circle
                        </i>
                        <div className="ms-3">Away</div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-danger md-14">
                          circle
                        </i>
                        <div className="ms-3">Disconnected</div>
                      </div>
                      <div className="d-flex align-items-center iq-sub-card border-0">
                        <i className="material-symbols-outlined text-gray md-14">
                          circle
                        </i>
                        <div className="ms-3">Invisible</div>
                      </div> */}
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>

              {/*  <Nav.Item as="li" className="d-lg-none">
              <Link
                to="/dashboard/app/profile"
                className="dropdown-toggle d-flex align-items-center"
              >
                <span className="material-symbols-outlined">person</span>
                <span className="mobile-text  ms-3">Profile</span>
              </Link>
      </Nav.Item>*/}
            </ul>
          </Container>
        </Nav>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        minWidth="xl"
        fullWidth
      >
        <DialogTitle>
          <Trans>Idea box</Trans>
        </DialogTitle>
        <DialogContent>
          <TextField
            label={t("Suggestion")}
            type="text"
            variant="outlined"
            multiline
            fullWidth
            value={suggestion}
            onChange={(e) => {
              const inputValue = e.target.value;
              setSuggestion(inputValue);
            }}
            style={{ marginTop: "8px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={sendSuggestion} color="primary">
            <Trans>Send suggestion</Trans>
          </Button>
        </DialogActions>
      </Dialog>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Header;
